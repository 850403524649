@mixin logo {
  display: flex;
  width: 100%;
  height: 36px;
  align-self: center;
  background-image: url("../../../assets/logo_full.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  margin: 0;
  padding: 0;
}

@mixin chat-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $terciary-surface-bg-light;
  padding: 6px;
  width: 100%;
  max-width: calc(100% - 12px);

  .chat-navbar-left {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;

    .logo {
      @include logo;
    }
    .logo-center {
      @include logo;
      background-position: center;
    }
  }

  .chat-navbar-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .menu {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .account-btn {
      color: $outline-light;
    }

    .account-select {
      width: 70%;
      margin-left: 8px;
      @include medium {
        width: 60%;
      }
      @include large {
        width: 30%;
      }
    }
  }
}
