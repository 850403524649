.footer {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $secondary-container-light;
    box-sizing: border-box;

    .content {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      box-sizing: border-box;

      .version {
        font-size: 12px;
        color: gray;
      }
    }
  }

  span {
    @include property-responsive-md3(font-size, $label-medium);
    color: $on-surface-variant-light;
  }
}
