$headerHeightTable: 36px;
$headerHeight: 56px;

@mixin content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100vh - $navbar-height * 4);
  box-sizing: border-box;
}

.briocard {
  display: flex;
  flex-direction: column;
  background-color: $background-light;
  max-width: 100%;
  box-sizing: border-box;

  @include large {
    border-radius: 16px;
    margin: 12px;
    margin-bottom: 8px;
    margin-top: 8px;
    border: 1px solid $outline-extra-light;
  }

  .header {
    display: flex;
    flex-direction: row;
    height: $headerHeight !important;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: $secondary-container-light;

    @include large {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }

    h1 {
      @include property-responsive-md3(font-size, $title-medium);
      color: black;
      font-weight: bold;
      margin-right: 4px;
      @include large {
        font-size: $title-medium-large;
      }
    }

    span {
      @include property-responsive-md3(font-size, $body-extra-small);
      color: $outline-light;
      text-align: left;
    }
  }

  .header-table {
    display: flex;
    flex-direction: row;
    height: $headerHeightTable !important;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: $secondary-container-light;

    @include large {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }

    h1 {
      @include property-responsive-md3(font-size, $title-medium);
      color: black;
      font-weight: bold;
      margin-right: 4px;
      @include large {
        font-size: $title-medium-large;
      }
    }

    span {
      @include property-responsive-md3(font-size, $body-extra-small);
      color: $outline-light;
    }
  }

  .header-selected {
    background-color: $tertiary-container-light;
  }

  .header-left {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .toolbar-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 8px;
  }

  .content {
    @include content;
    p {
      flex: 1 1;
      @include property-responsive-md3(font-size, $body-small);
      color: $on-surface-variant-light;
      margin: 0;
    }
  }

  .content-table {
    @include content;
    padding: 0;

    p {
      @include property-responsive-md3(font-size, $body-small);
      color: $on-surface-variant-light;
      margin: 0;
      padding: 16px;
    }

    .content-table-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
    }

    .content-table-filters-collapsed {
      padding: 0;
    }

    .table {
      min-width: 100%;
      overflow-x: auto;
      margin: 0;
      padding: 0;

      .table-container {
        max-height: 100%;
      }

      .table-row:nth-child(even) {
        background-color: $surface-light;
      }

      @include medium {
        .table-row:hover {
          background-color: $surface-variant;
          cursor: pointer;
        }
      }
      @include large {
        .table-row:hover {
          background-color: $surface-variant;
          cursor: pointer;
        }
      }

      .table-row:active,
      .table-row-selected {
        background-color: $terciary-95-light;
      }

      .table-row-head {
        background-color: $surface-light;
      }

      .table-cell-head {
        padding: auto;
        font-weight: bold;
        font-size: $body-medium;
        border-top: 2px solid $outline-extra-light;
        border-bottom: 2px solid $outline-extra-light;

        @include large {
          font-size: $body-medium-large;
        }
      }

      .table-cell {
        padding: auto;
        font-size: $body-medium;

        @include large {
          font-size: $body-medium-large;
        }

        &:hover {
          cursor: pointer;
        }

        .table-cell-new {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .list-item {
          display: flex;
          flex-direction: row;
          padding: 0;

          .list-item-avatar {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 12px;

            .avatar {
              width: 52px;
              height: 52px;
              border-width: 1px;
              border-style: solid;
              border-color: lightgray;
            }
            .avatar-image {
              object-fit: contain;
            }
            .avatar-view-icon {
              padding: 4px;
              padding-right: 16px;
            }
          }

          .list-item-text {
            max-width: 100%;
            padding: 0;
            margin: 0;
          }
        }
      }

      .checkbox {
        padding: auto;
      }

      .table-pagination {
        max-width: 100%;
      }
    }
  }
}
