// Material Design 3 typography guidelines
$display-large: 57px;
$display-medium: 45px;
$display-small: 36px;

$headline-large: 32px;
$headline-medium: 28px;
$headline-small: 24px;

$title-large: 22px;
$title-medium-large: 18px;
$title-medium: 16px;
$title-small: 14px;

$label-large: 14px;
$label-medium: 12px;
$label-small: 11px;

$body-large: 16px;
$body-medium-large: 15px;
$body-medium: 14px;
$body-small: 12px;
$body-extra-small: 10px;
