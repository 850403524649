$navbar-height: 64px;

.navbar {
  .toolbar {
    display: flex;
    flex-direction: row;
    background-color: $background-light;
    justify-content: space-between;
    height: $navbar-height;
    padding-left: 0;
    padding-right: 0;

    @include medium {
      padding-left: 8px;
      padding-right: 8px;
    }
    @include large {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .toolbar-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .logo {
      height: 36px;

      &:hover {
        cursor: pointer;
      }

      @include large {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .sidebar-btn {
      display: flex;
      @include large {
        display: none;
      }
    }

    @keyframes fadeInFromLeft {
      0% {
        opacity: 0;
        transform: translateX(-50px);
      }
      60% {
        opacity: 0;
      }
      65% {
        opacity: 0.3;
      }
      80% {
        opacity: 0.6;
        transform: translateX(10px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .siex-pending-btn {
      margin-left: 8px;
      margin-right: 8px;
      padding: 4px;
      background-color: $warning-color;
      opacity: 0;
      animation: fadeInFromLeft 0.3s ease-in forwards 0.5s;
    }

    .siex-errors-btn {
      margin-left: 8px;
      margin-right: 8px;
      padding: 4px;
      background-color: $error-light;
      opacity: 0;
      animation: fadeInFromLeft 0.3s ease-in forwards 0.5s;
    }

    .siex-badge {
      opacity: 0;
      animation: fadeIn 0.2s ease-in forwards 0.8s;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }

    .menu-expanded {
      display: none;

      @include medium {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
      @include large {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: auto;
      }

      .account-select {
        margin-left: 8px;
        min-width: 250px;
        max-width: 100%;
      }
    }
  }

  .toolbar-right {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .avatar {
      width: 40px;
      height: 40px;
      background-color: $primary-light;
    }

    .link-btn {
      display: none;

      @include medium {
        display: flex;
        margin-left: 8px;
      }

      @include large {
        display: flex;
        margin-left: 8px;
      }
    }

    .menu-collapsed {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      @include medium {
        display: none;
      }

      @include large {
        display: none;
      }

      .account-btn {
        color: $outline-light;
      }
    }
  }
}
