.whatsappCode {
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: var(--doc-height);
    width: 100vw;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 24px;
      width: calc(100% - 48px);

      @include medium {
        max-width: 600px;
      }
      @include large {
        max-width: 600px;
      }

      .resend-code-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 16px;

        span {
          margin-right: 4px;
        }
      }
    }
  }
}
