.dialog {
  box-sizing: border-box;

  .title {
    @include property-responsive-md3(font-size, $title-large);
    padding-bottom: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 100%;
    box-sizing: border-box;

    .text {
      @include property-responsive-md3(font-size, $title-small);
      padding-bottom: 8px;
      padding-top: 0;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: 100%;
  }

  .form-control {
    max-width: 100%;

    &:first-child {
      margin-top: 16px;
    }

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .form-input {
    display: flex;
    flex: 1 1;
  }

  .form-input-row {
    width: 49%;
  }

  .form-input-row-80 {
    width: 79%;
  }

  .form-input-row-20 {
    width: 19%;
  }

  .form-input-row-70 {
    width: 69%;
  }

  .form-input-row-30 {
    width: 29%;
  }

  .input {
    margin-top: 16px;
  }

  .grow-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
