.login {
  height: 100%;
  min-height: 60%;

  p {
    align-self: flex-start;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;

    @include medium {
      flex-direction: row;
    }
    @include large {
      flex-direction: row;
    }
  }

  .image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $secondary-container-light;
    height: auto;
    padding: 48px;

    @include medium {
      width: 60%;
      padding: 54px;
    }
    @include large {
      width: 60%;
      padding: 64px;
      justify-content: flex-start;
      padding-top: 10%;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 24px;

    .logo {
      display: flex;
      width: 60px;
      height: 50px;
      align-self: flex-start;
      background-image: url("../../assets/logo_simple.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 8px;
    }
  }

  .login-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    height: auto;
    width: 100%;
    box-sizing: border-box;

    @include medium {
      width: 40%;
    }
    @include large {
      width: 40%;
    }

    .tab {
      display: flex;
      flex: 1 1;
    }

    .tabbar {
      background-color: $background-light;
      margin-bottom: 8px;
    }

    .login-section-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 24px;
      padding-top: 0;
      min-height: 500px;
      max-height: 100%;
      width: 100%;
      box-sizing: border-box;

      .whatsapp-warning {
        text-align: left;
        color: $error-light;
        font-size: 14px;
        width: 100%;
      }

      .form-input {
        width: 100%;
        margin: 16px 0;
      }
    }
  }

  .logo {
    display: flex;
    width: 140px;
    height: 60px;
    align-self: center;
    background-image: url("../../assets/logo_full.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 8px;
    margin-bottom: 8px;

    @include medium {
      height: 100px;
      width: 180px;
      margin: 0;
      align-self: flex-start;
    }
    @include large {
      height: 100px;
      width: 180px;
      margin: 0;
      align-self: flex-start;
    }
  }

  .fb-image {
    width: 100vw;
    height: 50vmin;
    background-image: url("../../assets/fb_image.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 12px;
  }

  .login-email {
    width: 100%;
    margin-top: 16px;
  }

  .login-providers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .provider-btn-container {
      width: 100%;
      padding: 16px;
      margin-bottom: 16px;

      .provider-btn-content {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          margin-left: 8px;
        }
      }
    }
  }

  .tf {
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
  }

  form {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  h1 {
    font-size: $display-medium;
    margin: 0;
    text-align: center;
    font-weight: 100;
    color: $terciary-light;
  }

  h2 {
    @include property-responsive-md3(font-size, $headline-large);
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
  }

  h3 {
    @include property-responsive-md3(font-size, $title-large);
    @include property-responsive-md3(line-height, $headline-large);
    margin: 0;
    padding: 0;
    text-align: center;
  }

  p {
    text-align: center;
    line-height: 20px;
    max-width: 95%;

    @include medium {
      line-height: 24px;
      max-width: 80%;
    }
    @include large {
      line-height: 24px;
      max-width: 80%;
    }
  }

  a {
    color: $terciary-light;
    text-decoration: underline;
  }
}
