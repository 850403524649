.account-sync {
  display: flex;
  flex-direction: column;
  background-color: $background-light;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 24px;

  .content {
    margin-top: 16px;
  }

  .resend-code-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;

    span {
      margin-right: 4px;
    }
  }
}
