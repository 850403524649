.empty-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-light;
  border-radius: 16px;
  margin: 12px;
  padding: 24px;
  border: 1px solid $secondary-light;

  .icon {
    color: $secondary-light;
  }
}
