.policies {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 8px;
    max-width: calc(100% - 16px);

    @include large {
      max-width: 70%;
      padding: 24px;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 8px;

      @include large {
        font-size: 28px;
      }
    }

    ul li,
    p,
    span {
      font-size: 14px;
      margin-top: 4px;

      @include large {
        font-size: 16px;
      }
    }

    p,
    div {
      margin-top: 4px;
      margin-bottom: 16px;
    }
  }
}
