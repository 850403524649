.msg-status-icon-right {
  margin-right: 2px;
  color: $secondary-light;
}
.msg-status-icon-read-right {
  margin-right: 2px;
  color: $chat-message-read-color;
}
.msg-status-icon-not-sent-right {
  margin-right: 2px;
  color: $secondary-light;
}

.msg-status-icon-left {
  margin-left: 2px;
  color: $secondary-light;
}
.msg-status-icon-read-left {
  margin-left: 2px;
  color: $chat-message-read-color;
}
.msg-status-icon-not-sent-left {
  margin-left: 2px;
  color: $secondary-light;
}
