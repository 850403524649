@mixin chat-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .chat-list-fixed {
    display: none;
    height: 100%;

    @include medium {
      display: flex;
      flex-direction: column;
      width: 360px;
    }
    @include large {
      display: flex;
      flex-direction: column;
      width: 400px;
    }

    .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .search-bar {
        background-color: $terciary-surface-bg-light;
        padding: 12px;
        padding-top: 0;
        width: 100%;
        max-width: calc(100% - 24px);
      }

      .search-bar-input {
        background-color: $background-light;
      }

      .empty-chats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 100%;
        max-width: calc(100% - 16px);

        .empty-chat-text {
          font-size: $title-medium;
          color: $primary-light;
          text-align: center;
        }
      }

      .scrollable-content {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }

  .chat-list-temp-none {
    display: flex;

    @include medium {
      display: none;
    }
    @include large {
      display: none;
    }
  }

  .chat-list-temp {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;

    .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      overflow: hidden;

      .search-bar {
        background-color: $terciary-surface-bg-light;
        padding: 12px;
        padding-top: 0;
        width: 100%;
        max-width: calc(100% - 24px);
      }

      .search-bar-input {
        background-color: $background-light;
      }

      .empty-chats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        width: 100%;

        .empty-chat-text {
          font-size: $label-medium;
          color: $secondary-light;
          text-align: center;
          margin-bottom: 8px;
        }
      }

      .scrollable-content {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }
}
