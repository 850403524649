.layout-chat-container {
  display: flex;
  flex-direction: column;
  background-color: $background-light;
  width: 100vw;
  height: 100vh;
  height: var(--doc-height);
  overflow: hidden;

  .chat-navbar {
    @include chat-navbar;
  }

  .content {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    .chat-list {
      @include chat-list;
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      border-left: 1px solid $outline-extra-light;

      .chat-conversation {
        @include chat-conversation;
      }
    }

    .chat-home-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: $terciary-surface-bg-light;
      border-left: 1px solid $outline-extra-light;
      padding: 24px;
      width: 100%;
      max-width: calc(100% - 48px);

      .logo {
        display: flex;
        height: 84px;
        align-self: center;
        margin: 0;
        padding: 0;
        border: 0;
      }

      h1 {
        font-size: $display-small;
        color: #3b6a00;
      }

      h2 {
        font-size: $title-large;
        color: $primary-light;
        text-align: center;
        margin: 0;
      }

      h3 {
        font-size: $title-small;
        color: $primary-light;
        text-align: center;
      }

      strong {
        font-weight: bold;
      }

      figure {
        border: none;
      }

      figcaption {
        font-size: $display-medium;
        margin-top: 8px;
      }
    }
  }
}
