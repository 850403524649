.onBoarding {
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    height: var(--doc-height);
    width: 100vw;

    .header {
      .logo {
        display: flex;
        width: 140px;
        height: 60px;
        align-self: center;
        background-image: url("../../assets/logo_full.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 8px;
        margin-bottom: 8px;

        @include medium {
          height: 100px;
          width: 180px;
          margin: 0;
          align-self: flex-start;
        }
        @include large {
          height: 100px;
          width: 180px;
          margin: 0;
          align-self: flex-start;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 16px;
      height: 100%;
      width: calc(100% - 32px);

      @include medium {
        max-width: 500px;
      }
      @include large {
        max-width: 500px;
      }

      span {
        font-size: 16px;
        margin-bottom: 8px;
        text-align: left;
      }

      .form-section {
        width: 100%;

        h3 {
          font-size: $title-large;
          color: black;
        }
      }
    }
  }
}
