@mixin chat-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 85%;
  margin-top: 12px;

  .avatar {
    width: 32px;
    height: 32px;
  }

  .avatar-transparent {
    width: 32px;
    height: 32px;
    background-color: transparent;
  }
}

@mixin chat-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  margin-left: 8px;
  padding: 12px;
  padding-bottom: 8px;

  .title-brioagro,
  .title-client {
    font-size: $title-medium;
    font-weight: bold;
    color: $chat-message-title-color;
    text-align: left;
  }

  .title-system {
    font-size: $label-medium;
    color: gray;
    text-align: left;
  }

  audio {
    min-width: 100%;
  }

  .header-img {
    min-width: 100px;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 300px;

    &:hover {
      cursor: pointer;
    }
  }

  .message-text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .message-text {
      display: flex;
      flex: 1;
      font-size: $title-medium;
      color: $chat-message-text-color;
      text-align: left;
      margin: 0;
      padding: 0;
      margin-top: 4px;
      max-height: calc($title-medium * 10);
      overflow: hidden;
    }

    .message-text-expanded {
      max-height: 100%;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2px;
    width: 100%;

    .time {
      font-size: $title-small;
      color: $secondary-light;
    }
  }
}

@mixin message-date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $chat-message-system-color;
  width: fit-content;
  align-self: center;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;

  .date-text {
    font-size: $title-medium;
    color: $chat-message-text-color;
    text-align: center;
  }
}

@mixin chat-conversation {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .chat-message-empty {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 24px;
    width: 100%;
    max-width: calc(100% - 48px);

    .message-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      padding-top: 0;
      border-radius: 8px;
      background-color: $chat-message-system-color;

      .message-text {
        display: flex;
        flex: 1;
        font-size: $title-medium;
        color: $chat-message-text-color;
        text-align: center;
        margin: 0;
        padding: 0;
        margin-top: 12px;
      }
    }
  }

  .chat-message-list {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    justify-content: flex-start;
    padding-inline: 12px;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 100%;
    width: 100%;
    max-width: calc(100% - 24px);
    overflow-y: auto;

    .linear-progress {
      width: 100%;
      max-width: calc(100% - 24px);
      height: 100px;
    }

    .message-date-container-sticky {
      @include message-date-container;
      position: absolute;
      top: calc($chat-conversation-header-height + 24px);
    }

    .chat-message-brioagro,
    .chat-message-system,
    .chat-message-client {
      @include chat-message;

      .chat-content-brioagro {
        @include chat-content;
        background-color: $chat-message-brioagro-color;

        audio::-webkit-media-controls-panel {
          background-color: $chat-message-brioagro-color;
          padding: 0;
          margin: 0;
        }
      }
      .chat-content-system {
        @include chat-content;
        background-color: $chat-message-system-color;

        audio::-webkit-media-controls-panel {
          background-color: $chat-message-system-color;
          padding: 0;
          margin: 0;
        }
      }
      .chat-content-client {
        @include chat-content;
        background-color: $chat-message-client-color;

        audio::-webkit-media-controls-panel {
          background-color: $chat-message-client-color;
          padding: 0;
          margin: 0;
        }
      }
    }

    .chat-message-advisor {
      @include chat-message;
      align-self: flex-end;

      .chat-content-advisor {
        @include chat-content;
        background-color: $chat-message-advisor-color;

        audio::-webkit-media-controls-panel {
          background-color: $chat-message-advisor-color;
          padding: 0;
          margin: 0;
        }
      }
    }

    .message-date-container {
      @include message-date-container;
    }
  }

  .chat-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: calc(100% - 16px);

    .chat-input {
      display: flex;
      flex: 1;
      margin-right: 8px;
    }
  }
}
