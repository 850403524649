.layout-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-top: $navbar-height;
  background-color: $background-light;
  max-width: 100%;
  overflow: hidden;
  max-height: calc(100vh - $navbar-height);
}

.layout-content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  max-width: 100%;
  overflow: hidden;
}

.layout-sidebar {
  display: flex;
  background-color: $surface-light;
  z-index: 0;
}

.layout-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  min-height: calc(100vh - $navbar-height);
}

.layout-body-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: 100%;
}

.layout-screen {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  padding: 24px;

  .fb-image {
    width: 100%;
    height: 65vmin;
    background-image: url("../../assets/fb_image.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 24px;

    @include medium {
      height: 50vmin;
    }
    @include large {
      height: 50vmin;
    }
  }

  .logo-full {
    width: 100%;
    height: 12vmin;
    background-image: url("../../assets/logo_full.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 18px;
  }
}
