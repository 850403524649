@mixin chatListItem {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid $outline-extra-light;

  &:hover {
    cursor: pointer;
    background-color: $secondary-container-light;
  }
  &:active {
    background-color: $surface-variant;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-inline: 12px;

    .title {
      font-size: $title-medium;
      color: black;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .subtitle-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;

      .subtitle {
        font-size: $label-large;
        color: $secondary-light;
        text-align: left;
        margin-top: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 22px);
      }
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: flex-start;

    .last-msg-date {
      font-size: $body-medium;
      color: $secondary-light;
      text-align: right;
    }

    .unread-msgs-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      background-color: $primary-light;
      width: 20px;
      height: 20px;
      margin-top: 4px;
    }

    .unread-msgs-title {
      font-size: $body-medium;
      color: $background-light;
      text-align: center;
    }
  }
}

.chat-list-item {
  @include chatListItem;
}

.chat-list-item-selected {
  @include chatListItem;
  background-color: $surface-variant;
}
