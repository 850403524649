$primary-light: #386664;
$secondary-light: #57624a;
$terciary-light: #3b6a00;
$secondary-container-light: #a0cfcc;
$secondary-95-light: #c9faf7;
$on-surface-variant-light: #44483d;
$surface-light: #e3f6f4;
$background-light: white;
$on-secondary-container-light: #151e0b;
$tertiary-container-light: #b4f573;
$terciary-95-light: #d1ffa0;
$terciary-90-light: #b4f573;
$outline-light: #74796c;
$outline-extra-light: lightgray;
$surface-variant: #bbece8;
$surface-variant-95: #c9faf7;
$surface-variant-99: #f2fffd;
$error-light: #c62828;
$terciary-surface-bg-light: #eaf6dd;
$chat-message-read-color: #5cb106;
$chat-message-brioagro-color: #d1ffa0;
$chat-message-system-color: lightgray;
$chat-message-advisor-color: #dbe7c8;
$chat-message-client-color: $surface-variant;
$chat-message-title-color: black;
$chat-message-text-color: #454444;
$success-color: #5cb106;
$info-color: #0288d1;
$warning-color: #ed6c02;
