.screen-content-layout {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: $background-light;
  max-width: 100%;
  max-height: 100%;
}

.tab-container-sticky {
  position: sticky;
  .tab-large {
    font-size: 54px !important;
    @include large {
      font-size: 54px !important;
    }
  }
}

.tab-container {
  margin-bottom: 8px;
}

.tab {
  display: flex;
  flex: 1 1;
}

.tabbar {
  background-color: $background-light;
  margin-bottom: 8px;
}
