.chat-conversation-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding-left: 0;
  background-color: $surface-variant;
  height: $chat-conversation-header-height;
  width: 100%;
  max-width: calc(100% - 8px);

  @include medium {
    padding: 12px;
    max-width: calc(100% - 24px);
  }
  @include large {
    padding: 12px;
    max-width: calc(100% - 24px);
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-inline: 12px;

    .title {
      font-size: $title-medium;
      color: black;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .subtitle {
      font-size: $label-large;
      color: $secondary-light;
      text-align: left;
      margin-top: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 22px);
    }
  }
}
